<template>
	<PopupTwoCol name="grn"
	text="<h3>Gare routière nord</h3><br/><div class='popup__statistics'><p class='dark__red'>Quelques chiffres</p><p>14 postes de chargement camion</p><p>40 000 camions / an</p></div><br/><p>La gare routière permet aux camions de charger les produits finis (essence, gazole, kérosène, fioul domestique) pour assurer la distribution vers les clients (stations-service, aéroports, particuliers). La raffinerie possède une seconde gare routière (au sud) équipée de 6 postes de chargement, destinés aux produits bitumes et fiouls lourds.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/GRN_quais_bprf8b_c_scale_w_350.jpg 350w,
		img/popup/GRN_quais_bprf8b_c_scale_w_719.jpg 719w,
		img/popup/GRN_quais_bprf8b_c_scale_w_970.jpg 970w"
		data-src="img/popup/GRN_quais_bprf8b_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
